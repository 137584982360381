<template>
  <div class="page">
    <div class="title">
      <span>企业店铺信息</span>
    </div>
    <div class="content">
      <el-descriptions title="企业信息" class="margin-top" :column="2" border>
        <el-descriptions-item>
          <template slot="label"> 企业类型 </template>
          {{ identifyInfo.companyName || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 企业注册名称 </template>
          {{ identifyInfo.companyName || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 企业注册编号 </template>
          {{ identifyInfo.businessLicenseNo || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 企业注册区域 </template>
          {{ identifyInfo.registerArea || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 公司营业地址 </template>
          {{ identifyInfo.businessAddress || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 主营业务 </template>
          {{ identifyInfo.businessScope || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 企业邮箱 </template>
          {{ identifyInfo.companyEmail || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 固定电话 </template>
          {{ identifyInfo.companyPhone || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 营业执照 </template>
          <el-image
            style="width: 160px; height: 130px"
            :src="identifyInfo.certificatePhoto"
          ></el-image>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="法人信息" class="margin-top" :column="2" border>
        <el-descriptions-item>
          <template slot="label"> 法人姓名 </template>
          {{ identifyInfo.legalName || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 法人身份证号 </template>
          {{ identifyInfo.idCardNo || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 法人手机号 </template>
          {{ identifyInfo.legalPhone || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 法人邮箱 </template>
          {{ identifyInfo.legalEmail || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 身份证正面照片 </template>
          <el-image
            style="width: 160px; height: 130px"
            :src="identifyInfo.idCardFrontUrl"
          ></el-image>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 身份证反面照片 </template>
          <el-image
            style="width: 160px; height: 130px"
            :src="identifyInfo.idCardBackUrl"
          ></el-image>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 身份证有效期 </template>
          {{ identifyInfo.effectiveDate || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 联系地址 </template>
          {{ identifyInfo.legalAddress || "无"}}
        </el-descriptions-item>
      </el-descriptions>
	  
      <el-descriptions v-if="resData.userShop" title="店铺信息" class="margin-top" :column="1" border>
        <el-descriptions-item>
          <template slot="label"> 店铺名称 </template>
          {{ identifyInfo.shopName || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 钱包认证状态 </template>
          {{ resData.heli != null? heliTypeObj[resData.heli]: '未认证'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 银联认证状态 </template>
          {{ resData.chinaums != null? uacTypeObj[resData.chinaums]: '未认证'}}
        </el-descriptions-item>
      </el-descriptions>
	  
      <el-descriptions v-if="resData.heli == 5"  title="钱包认证" class="margin-top" :column="2" border>
        <el-descriptions-item>
          <template slot="label"> 绑定银行 </template>
          {{ identifyInfo.bankName || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 银行卡号 </template>
          {{ identifyInfo.bankNo || "无"}}
        </el-descriptions-item>
      </el-descriptions>
	  
      <el-descriptions v-if="resData.chinaums == 1"  title="银联认证" class="margin-top" :column="2" border>
        <el-descriptions-item>
          <template slot="label"> 绑定银行 </template>
          {{ identifyInfo.chinaumsBankName || "无"}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label"> 银行卡号 </template>
          {{ identifyInfo.chinaumsBankNo || "无"}}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <!-- 回到顶部 -->
    <el-backtop></el-backtop>
  </div>
</template>
<script>
export default {
  data() {
    return {
      steps: 1,
	  resData: {
		  chinaums: null,
		  heli: null,
		  userShop: false,
	  },
		heliTypeObj: {1: '进件未成功', 2: '未上传商户资料', 3: '未开通钱包', 4: '未签约', 5: '已签约' },
		uacTypeObj: {0: '审核中', 1: '审核成功', 2: '审核失败'},
      identifyInfo: {
        type: "",
        certificateType: "",
        certificatePhotoUrl: "",
        certificatePhotoSize: "",
        certificatePhotoType: "image/jpeg",
        registerArea: "",
        registerName: "",
        registerNo: "",
        businessAddress: "",
        businessScope: "",
        companyEmail: "",
        companyPhone: "",
        legalName: "",
        idCardNo: "",
        effectiveDate: "",
        idCardFrontUrl: "",
        idCardFrontSize: "",
        idCardFrontType: "image/jpeg",
        idCardBackUrl: "",
        idCardBackSize: "",
        idCardBackType: "image/jpeg",
        legalAddress: "",
        legalPhone: "",
        legalEmail: "",
      },
    };
  },
  computed: {},
  mounted() {
    this.getIdentifyInfo();
    this.getUserInfo();
  },
  methods: {
	// 获取店铺信息
	// 银联 0: 审核中 1: 审核成功，2: 审核失败
	// 钱包 1: 进件未成功，2: 未上传商户资料，3: 未开通钱包，4: 未签约，5: 已签约
	getUserInfo() {
		this.$http.getContractResult().then((res) => {
			  if (res.code == 0 && res.data) {
				  this.resData = res.data
			  }
		});
	},
	  // 获取详细信息
    getIdentifyInfo() {
      this.$http.getIdentifyInfo({ detail: true }).then((res) => {
        if (res.code == 0 && res.data) {
          this.identifyInfo = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
div.page {
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
  padding: 20px 0;

  div.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40vw;
    min-width: 500px;
    margin: auto;
    padding: 20px 0;

    & > span:first-of-type {
      font-size: 18px;
      color: #303133;
    }

    span {
      font-size: 16px;
      color: #606266;
    }
  }

  div.content {
    width: 40vw;
    min-width: 500px;
    min-height: 100vh;
    margin: auto;
    // padding: 20px;
    background: #ffffff;

    ::v-deep .el-descriptions-item__label {
      font-weight: 400 !important;
    }
    ::v-deep .el-descriptions {
      padding-top: 20px;
    }
  }
}
</style>
